import { getContentPage } from "helpers/backend_helper";
import React, { useEffect, useState } from "react";
import { Container, Spinner } from "react-bootstrap";
import { Fade } from "react-reveal";
import { useSearchParams } from "react-router-dom";

const pages = [{
    "terms-of-services": "Terms of Services",
    "privacy-policy": "Privacy Policy"
}];

const ContentPage = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [searchParams] = useSearchParams();
    const slug = searchParams.get('type');

    useEffect(async () => {
        if (slug) {
            try {
                setLoading(true);
                const data = await getContentPage(slug);
                setData(data);
                setLoading(false);
            }
            catch (e) {
                console.log("e", e);
                setLoading(false)
            } finally {
                setLoading(false)
            }
        }
    }, [slug]);
    console.log("page data", data);

    return (
        <Fade>
            <section className="content-page">
                <Container>
                    {loading ?
                        <div className="d-flex justify-content-center mt-5">
                            <Spinner color="#000" />
                        </div>
                        : null}
                    {!data && !loading ?
                        <div className="d-flex justify-content-center mt-5">
                            <span>No data found!</span>
                        </div>
                        :
                        <>
                            <h2>{data?.title || pages[slug]}</h2>
                            <div className="mt-5" dangerouslySetInnerHTML={{ __html: data?.content }}></div>
                        </>
                    }

                </Container>
            </section>
        </Fade>
    );
}

export default ContentPage;