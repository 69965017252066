import React from "react";
import { Link } from "react-router-dom";

 
const Blogcard = ({ posts }) => {
  return (
    <div className="cardblog">
      <Link to="/blogdetail">
      <div className="cardImg_wrap">
        <img src={posts.image} alt="" className="img-fluid" />
      </div>
      </Link>
      <div className="postcard_body text-start">
        <h3>{posts.title}</h3>
        <p className="description_text">{posts.content}</p>

        <div className="dateautor d-flex align-items-center justify-content-between">
          <p>{posts.date}</p>
          <p>
            <i>{posts.author}</i>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Blogcard;
