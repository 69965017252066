import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Blogdetails = (props) => {
  return (
    <section className="blog_page">
      <Container>
        <h2 className="blog_title">{props.title}</h2>

        <div className="Single_page mt-lg-5">
          <Row>
            <Col lg={8} md={7} sm={12}>
              <div className="single_page_content text-start">
                <h3>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Libero repudiandae ducimus deserunt, repellendus voluptatem
                  ipsum.
                </h3>
                <div className="image_banner_single my-4">
                  <img
                    src="https://via.placeholder.com/900x500"
                    alt=""
                    className="img-fluid"
                  />
                </div>

                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. A
                  numquam reprehenderit atque omnis nulla officia. Excepturi
                  pariatur quae suscipit a dolorem assumenda, facilis fugiat
                  neque, voluptatum, in ex corporis dolor. Natus eos aperiam
                  quod adipisci quas, soluta esse veritatis commodi tempora
                  facere dolores quae quis iure quia accusantium odit id.
                </p>

                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. A
                  numquam reprehenderit atque omnis nulla officia. Excepturi
                  pariatur quae suscipit a dolorem assumenda, facilis fugiat
                  neque, voluptatum, in ex corporis dolor. Natus eos aperiam
                  quod adipisci quas, soluta esse veritatis commodi tempora
                  facere dolores quae quis iure quia accusantium odit id.
                </p>

                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. A
                  numquam reprehenderit atque omnis nulla officia. Excepturi
                  pariatur quae suscipit a dolorem assumenda, facilis fugiat
                  neque, voluptatum, in ex corporis dolor. Natus eos aperiam
                  quod adipisci quas, soluta esse veritatis commodi tempora
                  facere dolores quae quis iure quia accusantium odit id.
                </p>

                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. A
                  numquam reprehenderit atque omnis nulla officia. Excepturi
                  pariatur quae suscipit a dolorem assumenda, facilis fugiat
                  neque, voluptatum, in ex corporis dolor. Natus eos aperiam
                  quod adipisci quas, soluta esse veritatis commodi tempora
                  facere dolores quae quis iure quia accusantium odit id.
                </p>

                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. A
                  numquam reprehenderit atque omnis nulla officia. Excepturi
                  pariatur quae suscipit a dolorem assumenda, facilis fugiat
                  neque, voluptatum, in ex corporis dolor. Natus eos aperiam
                  quod adipisci quas, soluta esse veritatis commodi tempora
                  facere dolores quae quis iure quia accusantium odit id.
                </p>

                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. A
                  numquam reprehenderit atque omnis nulla officia. Excepturi
                  pariatur quae suscipit a dolorem assumenda, facilis fugiat
                  neque, voluptatum, in ex corporis dolor. Natus eos aperiam
                  quod adipisci quas, soluta esse veritatis commodi tempora
                  facere dolores quae quis iure quia accusantium odit id.
                </p>
              </div>
            </Col>
            <Col lg={4} md={5} sm={12}>
              <div className="related_post text-start">
                <h3>Related posts</h3>
                <ul>
                  {[...Array(4)].map((item, idx) => {
                    return (
                      <li key={idx}>
                        <a href="#">
                          <div className="related_box">
                            <div className="related_thumnail me-3">
                              <img
                                src="https://via.placeholder.com/100x100"
                                alt=""
                                className="related_post_img img-fluid"
                              />
                            </div>
                            <div className="related_content">
                            <h5>Lorem ipsum dolor sit amet</h5>
                            <p>Posted on 22 Feb 2022</p>
                            </div>
                          </div>
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default Blogdetails;
