import React from "react";
import Header from "../Component/Header/Header";
import Home from "Pages/Home/home";
import Whatissetvr from "Pages/Home/whatissetvr";
import Kickstarter from "Pages/Home/kickstarter";
import Funstructure from "Pages/Home/funstructure";
import Excercise from "Pages/Home/excercise";
import Benefits from "Pages/Home/benefits";
import Aboutsetvr from "Pages/Home/aboutsetvr";
import Comingsoon from "Pages/Home/comingsoon";
import Museum from "Pages/Home/museum";
// import Museumaddress from "Pages/Home/museumaddress";
import Testimonial from "Pages/Home/testimonial";
import Contactus from "Pages/Home/contactus";
import Footer from "Component/Footer/Footer";
import Homenew from "Pages/Home/homenew";
import Features from "Pages/Home/features";

export default function Hometheme() {
  return (
    <>
  
      <Header />
      <Home />
      {/* <Homenew/> */}
      <Whatissetvr />
      <Kickstarter />
      <Funstructure />
      {/* <Features/> */}
      <Excercise />
      <Benefits />
      <Aboutsetvr />
      <Comingsoon />
      <Museum />
      {/* <Museumaddress/> */}
      <Testimonial />
      <Contactus />
      <Footer />
    </>
  );
}
