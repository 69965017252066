import { SettingsContext } from "context/SettingsProvider";
import { getSettings } from "helpers/backend_helper";
import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Fade } from "react-reveal";
import Slider from "react-slick";
import ReactPlayer from "react-player";

let defaultData = {
  question: "SetVR - XL Unleashed?",
  description: `SetVR XL is an intriguing, visually captivating, and
  stunningly life-like game with a hidden talent! That talent
  would be the opportunity to enhance one’s physical fitness,
  muscle memory, reflexes, and stamina, as well as to sharpen
  mindfulness. Anyone who is interested in practicing self
  defense will rise to the challenges in the game as they
  enjoy the many chances to improve their techniques and
  fitness levels.`,
  backgroundImage: "/images/bannergif.gif",
  initialSlider: [
    {
      image: "/images/banner1.png",
    },
    {
      image: "/images/banner.png",
    },
    {
      image: "/images/banner3.png",
    },
    {
      image: "/images/banner1.png",
    },
    {
      image: "/images/banner.png",
    },
    {
      image: "/images/banner3.png",
    },
  ],
};

const Home = () => {
  const [sectionOne, setSectionOne] = useState(defaultData);
  const [bannervedio, setbannervedio] = useState(true);
  const [settings] = useContext(SettingsContext);

  useEffect(() => {
    setTimeout(() => {
      setbannervedio(false);
    }, 6000);
  }, [bannervedio]);

  useEffect(() => {
    if (settings?.sectionOne) {
      const data = {
        question: settings?.sectionOne?.question || defaultData?.question,
        description:
          settings?.sectionOne?.description || defaultData?.description,
        backgroundImage:
          settings?.sectionOne?.backgroundImage || defaultData?.backgroundImage,
        initialSlider: settings?.sectionOne?.initialSlider?.length
          ? settings?.sectionOne?.initialSlider
          : defaultData?.initialSlider,
      };
      console.log("data", data);
      setSectionOne(data);
    }
  }, [settings]);

  const sliderSettings = {
    dots: false,
    fade: true,
    autoplay: true,
    infinite: true,
    autoplaySpeed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <Fade>
      <section id="Home" className="home_banner_main position-relative">
        {bannervedio ? (
          <>
            <div className="slidebanner_img position-relative">
              <Slider {...sliderSettings}>
                {sectionOne?.initialSlider?.map((slideimg, idx) => {
                  return (
                    <div key={idx}>
                      <img
                        src={slideimg.image}
                        alt=""
                        className="img-fluid w-100"
                      />
                    </div>
                  );
                })}
              </Slider>
              <div className="drop-main">
                <div className="s">S</div>
                <div className="e">E</div>
                <div className="t">T</div>
                <div className="v">V</div>
                <div className="r">R</div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="vedio_banner position-relative">
              {/* <div className="overalay_vedio"></div> */}
              {/* <img
                src={sectionOne?.backgroundImage}
                alt=""
                className="img-fluid w-100"
              /> */}
              {/* <video autoPlay loop muted className="w-100">
                <source src={sectionOne?.backgroundImage} type="video/mp4" />
              </video> */}

              <ReactPlayer
                url={sectionOne?.backgroundImage}
                playing
                loop
                muted
                width="100%"
                height="100vh"
              />

              <Container>
                <div className="vedio_conente text-center">
                  {/* <h1>
                    {sectionOne?.question}
                  </h1> */}
                  {/* <p>
                    {sectionOne.description}
                  </p> */}
                </div>
              </Container>
            </div>
          </>
        )}
      </section>
    </Fade>
  );
};

export default Home;
