import { SettingsContext } from "context/SettingsProvider";
import React, { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { defaultData } from "./kickstarter";

const Whatissetvr = () => {
  const [sectionTwo, setSectionTwo] = useState(defaultData);
  const [settings] = useContext(SettingsContext);

  useEffect(() => {
    if (settings?.sectionTwo) {
      const data = {
        question: settings?.sectionTwo?.question || defaultData?.question,
        description: settings?.sectionTwo?.description || defaultData?.description,
        backgroundImage: settings?.sectionTwo?.backgroundImage || defaultData?.backgroundImage,
        leftForgroundImage: settings?.sectionTwo?.leftForgroundImage || defaultData?.leftForgroundImage,
        rightForgroundImage: settings?.sectionTwo?.rightForgroundImage?.length ? settings?.sectionTwo?.rightForgroundImage : defaultData?.rightForgroundImage,
        sliderBackgroundmage: settings?.sectionTwo?.sliderBackgroundmage || defaultData?.sliderBackgroundmage,
        afterSliderStar: settings?.sectionTwo?.afterSliderStar || defaultData?.afterSliderStar
      };

      setSectionTwo(data);
    }
  }, [settings])

  return (
    <section className="whatissetvr comon_pad">
      <Container>
        <div className="setvr_content">
          <h2>
            <span>
              {sectionTwo?.question?.slice(0, 2)}
            </span>
            {sectionTwo?.question?.slice(2)}
          </h2>

          <p>
            {sectionTwo?.description}{" "}
          </p>
        </div>
      </Container>
    </section>
  );
};

export default Whatissetvr;
